exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-belmodash-tsx": () => import("./../../../src/pages/belmodash.tsx" /* webpackChunkName: "component---src-pages-belmodash-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-carreiras-tsx": () => import("./../../../src/pages/carreiras.tsx" /* webpackChunkName: "component---src-pages-carreiras-tsx" */),
  "component---src-pages-contato-tsx": () => import("./../../../src/pages/contato.tsx" /* webpackChunkName: "component---src-pages-contato-tsx" */),
  "component---src-pages-debug-tsx": () => import("./../../../src/pages/debug.tsx" /* webpackChunkName: "component---src-pages-debug-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-quem-somos-tsx": () => import("./../../../src/pages/quem-somos.tsx" /* webpackChunkName: "component---src-pages-quem-somos-tsx" */),
  "component---src-pages-servicos-tsx": () => import("./../../../src/pages/servicos.tsx" /* webpackChunkName: "component---src-pages-servicos-tsx" */)
}

